@import "part.scss";

.menu-container {
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 110px;
  padding: 20px 20px 20px 20px;
  opacity: 1;
  transition: opacity 250ms ease-in;
  color: white;
  background: linear-gradient(180deg, black 0%, black 75%, transparent 100%);
}
@media screen and (max-width: 1023.99px) {
  .menu-container {
    height: 100px;
  }
}

.menu-container.hidden {
  display: inherit;
  opacity: 0;
  pointer-events: none;
}

.menu-container.disable {
  opacity: 0;
  z-index: -99;
  display: none;
}

.menu {
  width: 100%;
  height: 60px;
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
@media screen and (max-width: 1023.99px) {
  .menu {
    height: 50px;
  }
}

.menu-logo {
  width: 236px;
  height: 100%;
}
@media screen and (max-width: 1023.99px) {
  .menu-logo {
    width: 179px;
  }
}

.language-button-container {
  position: relative;
  cursor: pointer;
}

.menu-desktop .language-button-container {
  height: 100%;
}

.language-button * {
  stroke-width: 2px;
  transition: all 100ms ease-in-out;
}

.menu-desktop .language-button {
  height: 100%;
}

.language-button-container.language-en .label-jp, .language-button-container.language-jp .label-en {
  fill: white;
}

.language-button-container.language-en .label-en, .language-button-container.language-jp .label-jp {
  fill: black;
}

.language-button-container.language-jp .slider {
  transform: translate(0, 0);
}

.language-button-container.language-en .slider {
  transform: translate(50%, 0);
}

ul.menu-links {
  padding: 0;
}

ul.menu-links li {
  list-style: none;
}

ul.menu-links li a {
  position: relative;

  font-weight: 500;
  text-decoration: none;
  white-space: nowrap;

  --initialTextColor: #FFF;
  --slideTextColor: #272838;

  --initialBgColor: transparent;
  --slideBgColor: #FFF;

  color: var(--initialTextColor);

  background-image: linear-gradient(90deg,
    var(--initialBgColor) 0%,
    var(--initialBgColor) 50%,
    var(--slideBgColor) 50%,
    var(--slideBgColor) 100%);
  background-size: 300%;
  transition:
    background-position .1s cubic-bezier(.47, .1, 1, .63),
    color .1s linear;
}

.menu-mobile ul.menu-links li a {
  left: -5px;
  font-size: 1.2rem;
  padding: 0 5px;
}

.menu-desktop ul.menu-links li a {
  left: 0px;
  font-size: 16px;
  padding: 0px;
}

ul.menu-links li a:hover {
  color: var(--slideTextColor);
  cursor: pointer;
  background-position: -75% 100%;
}

.menu-mobile {
  position: absolute;
  top: 0px;
  right: calc(env(safe-area-inset-right));
  opacity: 0%;
  visibility: hidden;
  animation: modal-deactivate 250ms ease-in-out;
  max-height: 735px;
}

.menu-mobile.is-active {
  opacity: 100%;
  display: inherit;
  visibility: visible;
  animation: modal-activate 250ms ease-in-out;
}

@media screen and (min-width: 768px) and (min-height: 775px) {
  .menu-mobile {
    width: calc(min(100%, 400px));
    height: 735px;
    max-height: calc(100vh - 40px);
  }
}

@media screen and (max-width: 767.99px),
       screen and (max-height: 774.99px) {
  .menu-mobile {
    position: fixed;
    bottom: calc(env(safe-area-inset-bottom));
    left: calc(env(safe-area-inset-left));
    padding: 20px;
  }
}

.menu-mobile .shadowed .shadowed-inner {
  position: relative;
  border-top: 2px solid white;
  width: 100%;
  height: calc(100% - 47px);
  top: 47px;
  padding: 5% 10%;
  display: flex;
  flex-flow: column;
  justify-content:space-around;
  align-items: center;
}

.menu-desktop {
  width: 728px;
  height: 100%;
}

.menu-desktop .shadowed .shadowed-inner {
  position: relative;
  padding: 10px;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
}

.menu-mobile-button {
  position: relative;
  width: 50px;
  height: 50px;
  right: 10px;
  border: 2px solid white;
  z-index: 1;
  transition: all 100ms ease-in-out;
}

@keyframes modal-activate {
  from {
    opacity: 0;
    visibility: visible;
  }
  to {
    opacity: 1;
    visibility: visible;
  }
}
@keyframes modal-deactivate {
  from {
    opacity: 1;
    visibility: visible;
  }
  to {
    opacity: 0;
    visibility: hidden;
  }
}

.menu-desktop .menu-item {
  height: 100%;
  margin-bottom: 0;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
}

.menu-desktop .menu-links {
  margin-left: 1.5rem;
}

.menu-desktop .menu-links .menu-item:not(:last-child) {
  margin-right: 1.5rem;
}

.menu-mobile .twitter-logo {
  width: 35%;
}

.menu-desktop .twitter-logo {
  height: 80%;
}

.menu-mobile .menu-links {
  width: 100%;
}

.menu-mobile .menu-buttons .language-button-container {
  width: 100%;
  max-width: 205px;
  max-height: 45px;
}

.menu-mobile .menu-links, .menu-mobile .menu-buttons {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

@media screen and (orientation: portrait) {
  .menu-mobile .menu-links, .menu-mobile .menu-buttons {
    flex-flow: column nowrap;
    padding: 0% 20%;
    flex: 1 1 auto;
  }

  .menu-mobile .menu-links {
    margin-top: 10%;
  }
}
@media screen and (orientation: landscape) {
  .menu-mobile .menu-links, .menu-mobile .menu-buttons {
    flex-flow: row nowrap;
    align-items: center;
  }

  .menu-mobile .menu-buttons {
    width: 75%;
  }

  .menu-mobile .menu-buttons .twitter-button-container {
    width: 60px;
    height: 60px;
  }

  .menu-mobile .menu-buttons .language-button-container {
    width: 205px;
    height: 45px;
  }
}

.twitter-logo {
  cursor: pointer;
  transform: scale(1.0);
  transition: transform 100ms ease-in;
}
.menu-mobile .twitter-logo {
  width: 100%;
  max-width: 60px;
}

.twitter-logo:hover {
  transform: scale(1.1);
}
