// fade
// apply "fade-init-hidden" if the element should be hidden at first
.fade-init-hidden, .fade-enter, .fade-exit-done {
  opacity: 0;
}
.fade-enter-done, .fade-exit {
  opacity: 1;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
}
