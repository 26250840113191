@import "~slick-carousel/slick/slick.css";
//@import "~slick-carousel/slick/slick-theme.css";

.slick-list {
    overflow: visible;
}

.slick-slide img {
    margin: auto;
    padding: 1%;
    overflow: visible;
    z-index: -1;
    outline: 1px solid transparent;
    width: auto;
    height: 100%;
}

.slick-track {
    display: flex !important;
}

.slick-slide {
    height: inherit !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.slick-slide > div > div {
    height: 100%;
    max-height: 75vh;
}

// prev/next

.slick-prev,
.slick-next
{
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 67px;
    height: 67px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
    z-index: 2;
}
.slick-prev
{
    left: -5%;
}
.slick-next
{
    right: -5%;
}

.slick-prev svg, .slick-next svg {
    height: 100%;
    object-fit: contain;
    overflow: visible;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    transition: transform 100ms ease-in
}
.slick-prev svg {
    left: 0;
}
.slick-next svg {
    right: 0;
}
.slick-next svg:hover, .slick-prev svg:hover {
  transform: translate(0, -50%) scale(1.1);
  transition: transform 100ms ease-in
}

.slick-prev svg .arrow, .slick-next svg .arrow {
    stroke-width: 2px;
    stroke: rgba($color: white, $alpha: 0.5);
}
@media screen and (max-width: 767.99px) {
    .slick-prev svg .arrow, .slick-next svg .arrow {
        stroke-width: 1px;
    }
}

@media screen and (min-width: 768px) {
    .slick-prev svg:hover .arrow, .slick-next svg:hover .arrow {
        stroke: white;
    }
}

.slick-prev svg .circle, .slick-next svg .circle {
    stroke-width: 1px;
    stroke: transparent;
    transition: all 100ms ease-in-out;
}

.slick-prev:active svg .arrow, .slick-next:active svg .arrow {
    stroke: black;
}
.slick-prev:active svg .circle, .slick-next:active svg .circle {
    stroke-width: 2px;
    stroke: white;
    fill: white;
    transition: all 100ms ease-in-out;
}
@media screen and (max-width: 767.99px) {
    .slick-prev:active svg .circle, .slick-next:active svg .circle {
        stroke-width: 1px;
    }
}

// dots

.slick-dotted.slick-slider
{
    margin-bottom: 30px;
}

.slick-dots
{
    position: absolute;
    bottom: -25px;

    display: block;

    width: 100%;
    padding: 0;
    margin: 0;

    list-style: none;

    text-align: center;
}

.slick-dots li
{
    position: relative;

    display: inline-block;

    width: 10px;
    height: 10px;
    margin: 0 10px;
    padding: 0;

    cursor: pointer;
}

.slick-dots li button
{
    font-size: 0;
    line-height: 0;
    display: block;
    width: 100%;
    height: 100%;
    padding: 0px;
    cursor: pointer;
    color: transparent;
    border: 2px solid #FFFFFF;
    border-radius: 50%;
    outline: none;
    background: transparent;
}

.slick-dots li.slick-active button
{
    background: #FFFFFF;
}
