.button-container {
  display: inline-block;
}

.shadowed {
  border: 2px solid white;
  background-color: black;
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  margin: 0px 10px 10px 0px;
  padding: 0px;
  // https://stackoverflow.com/questions/9983520/webkit-animation-is-leaving-junk-pixels-behind-on-the-screen/17822836
  box-shadow: 10px 10px 0px 0 white, 10px 10px 1px rgba(0, 0, 0, 0.05);
  transition: all 250ms ease-in-out;
}

button.shadowed {
  cursor: pointer;
  display: inherit !important;
}

button .shadowed-inner {
  color: white;
  font-family: 'IBM Plex Serif', 'Noto Serif JP', serif;
  font-weight: 500;
  font-size: 1.4rem;
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;
  border-width: 0 2px 2px 0;
  border-color: white;
  border-style: solid;
  background-color: black;
  width: -moz-calc(100% - 10px);
  width: -webkit-calc(100% - 10px);
  width: -o-calc(100% - 10px);
  width: calc(100% - 10px);
  height: -moz-calc(100% - 10px);
  height: -webkit-calc(100% - 10px);
  height: -o-calc(100% - 10px);
  height: calc(100% - 10px);
  top: 0px;
  left: 0px;
  margin: 0px 10px 10px 0px;
  transition: all 250ms ease-in-out;
}

button.shadowed:hover {
  // https://stackoverflow.com/questions/9983520/webkit-animation-is-leaving-junk-pixels-behind-on-the-screen/17822836
  box-shadow: 15px 15px 0px 0 white, 15px 15px 1px rgba(0, 0, 0, 0.05);
  transition: all 250ms ease-in-out;
}

button.shadowed .shadowed-inner:hover, button.shadowed:hover .shadowed-inner {
  top: -10px;
  left: -10px;
  color: black;
  background-color: white;
}
